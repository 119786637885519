/* eslint-disable prettier/prettier */
import React from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import Welcome from './components/Welcome';
import First from './components/First';
import Second from './components/Second';
import Third from './components/Third';
import Four from './components/Four';
import Five from './components/Five';
import Six from './components/Six';
import Seven from './components/Seven';
import Submit from './components/Submit';
import Providertax from './components/Providertax';
import ProvidertaxTwo from './components/ProvidetaxTwo';
const steps = [
  { id: 'Welcome', Component: Welcome },
  { id: 'First', Component: First },
  { id: 'Second', Component: Second },
  { id: 'Third', Component: Third },
  { id: 'Four', Component: Four },
  { id: 'Five', Component: Five },
  { id: 'Providertax', Component: Providertax },
  { id: 'ProvidetaxTwo', Component: ProvidertaxTwo },
  { id: 'Six', Component: Six },
  { id: 'Seven', Component: Seven },
  { id: 'Submit', Component: Submit },
];

const defaultData = {};

const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { formData, setForm, navigation };

  return <Component {...props} />;
};

export default MultiStepForm;
