import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../images/aurora_logo.png";
const Submit = ({ formData }) => {
  useEffect(
    () => {
    },
    [formData]
  );
  const refreshPage = () => {
    window.location.reload(false);
  };
  return (
    <>
      <div className="form">
        <div className="headerlogo">
          <img src={logo} alt="" />
          <div className="headercontent">
            <h3 className="form_heading text-center">Thanks!</h3>
          </div>
        </div>
        <div className="body_content margin-50">
          <div>
            <Container>
              <Row>
                <Col md={12} className="submit_text">
                  <label>
                    Thanks, your KiwiSaver account application is now underway!
                  </label>
                  <label>
                    Please verify your email address by clicking the link in the
                    email we have sent you.
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="footer">
                    <button
                      // type="button"
                      className="next grad_btn m-auto"
                      type="submit"
                      onClick={refreshPage}>
                      OK
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submit;
