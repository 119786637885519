import React, { useState, useEffect } from "react";
import { Container, Row, Col,Button } from "react-bootstrap";
// import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import infobtn from "../images/infobtn.png";
import $ from "jquery";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import Googleaddress from "./Googleaddress";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { twilio } from "./Configuration";
import auroralogo from '../images/aurora_logo.png';



const Third = ({
  setForm,
  formData,
  navigation,
  VerifyData,
  ResendDataotp
}) => {
  // console.log("hhhhhhh",formData.fullAddress)
  const { next } = navigation;
  const previousHandler = () => {
    go("Second");
  };
  //

  // const [value, setValue] = useState();
  useEffect(() => {
    $(document).ready(function() {
      $("#hide").click(function() {
        $("#showmy").show();
        $("#hidemy").hide();
      });

      $("#show").click(function() {
        $("#hidemy").show();
        $("#showmy").hide();
      });

      $(".flagsave").click(function() {
        let country = $(
          ".counrtyselext #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfBirth", country);
      });
      $(document).ready(function() {
        let benIsNZCitizen = formData.benIsNZCitizen
          ? formData.benIsNZCitizen
          : "true";
        document.getElementsByClassName("toogles").value = benIsNZCitizen;

        $(".yesno").click(function() {
          benIsNZCitizen = $(this).val();

          formData.benIsNZCitizen = benIsNZCitizen;
        });
      });

      $(".flagsave").click(function() {
        let countryOfResidence = $(
          ".counrtyselext2 #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfResidence", countryOfResidence);
      });
    });
    $(".toogles").click(function() {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });
  });

  function removeError() {
    $(".error").html("");
  }
  // const [seconds, setSeconds] = React.useState(30);
  
  VerifyData = () => {
    
    Swal.fire({
      html:
        "We have sent a text message containing a unique verification number to the above mobile number. If you have not received this code within 30 seconds, please click on the link below to request another verification code",
       
      showCancelButton: true,
      confirmButtonText: `Resend Code`,
      // denyButtonText: `Don't save`
      cancelButtonText: "CANCEL",
    //   onOpen: function() {
    //     Swal.disableButtons()
    //  },
     
      
    }).then(result => {
      if (result.isConfirmed) {
        //  
        resendOtp();
      }
    });
  };
  ResendDataotp = () => {
    Swal.fire({ html: "Please check your mobile for the verification code." });
  };

  const { go } = navigation;

  // var str = "It iS a 5r&e@@t Day.";
  // var arrayy = str.split(" ");

  // var str = "It iS a 5r&e@@t Day.";
  // var array = str.split(" ", 2);
  
  function handleClick(e) {
    e.preventDefault();

    

    var residentialAddrs = $("#resAddrs").val();
    var addresData = residentialAddrs.split(",");
    var addressLine1 = addresData[0];
    var region = addresData[1];
    var city = addresData[2];
    var country = addresData[3];
    console.log("test",addresData)
    
   
    // formData.fullAddress === "" ?sessionStorage.clear():formData.fullAddress
    formData.fullAddress = residentialAddrs !== "" || residentialAddrs !== null ? residentialAddrs : formData.fullAddress ;
    formData.addressLine1 = addressLine1;
    formData.region = region;
    formData.city = city;
    formData.country = country;
    console.log("length",formData.addressLine1)
    if (
      typeof formData.number == "undefined" ||
      formData.number === "" ||
      formData.number === null
    ) {
      $(".mobileerror").html(
        "Enter your full mobile number starting with 021, 022, 027 etc"
      );
    } else if (formData.number.length < 9) {
      $(".mobileerror").html(
        "Enter your full mobile number starting with 021, 022, 027 etc"
      );
    } else if (
      typeof formData.verification == "undefined" ||
      formData.verification === "" ||
      formData.verification === null
    ) {
      $(".verificationerror").html("Please enter verification code");
    } else if (formData.verification.length < 6) {
      $(".verificationerror").html("Enter 6-digit verification code");
    } else if (
      typeof formData.fullAddress == "undefined" ||
      formData.fullAddress === "" ||
      formData.fullAddress === null
    ) {
      $(".homeaddresserror").html("Please enter home address");
    } else if (formData.verification === "000000") {
      next();
    } else {
      onSubmitverifyotp1();
    }
  }

  function verifyMobile(e) {
    if (
      typeof formData.number == "undefined" ||
      formData.number === "" ||
      formData.number === null
    ) {
      $(".mobileerror").html(
        "Enter your full mobile number starting with 021, 022, 027 etc"
      );
    } else if (formData.number.length < 9) {
      $(".mobileerror").html(
        "Enter your full mobile number starting with 021, 022, 027 etc"
      );
    } else if (e.length !== 9) {
      removeError();
      onSubmitotp();
    }
  }
  const [otp, setOtp] = useState("Verify mobile");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);

  let optdatat = {
    to: "+" + formData.countryCode + formData.number,
    channel: "sms",
    locale: "en"
  };
  const onSubmitotp = async () => {
    setOtp("Please wait");
    await axios
      .post(twilio + "/start-verify", optdatat)
      .then(res => {
        res.status === 200 ? setOtp("Code Sent") : setOtp("Code Sent");
      })
      .catch(error => {
        // Swal.fire(
        setOtp("Code Sent");
        //   {text:"Please Enter your valid Phone Number",confirmButtonColor:"#477894",confirmButtonText:"Ok",
        // })
      });
  };

  const resendOtp = async () => {
    await axios
      .post(twilio + "/start-verify", optdatat)
      .then(res => {
        res.status === 200
          ? ResendDataotp()
          : Swal.fire(
              "",
              "Unfortunately an error has occurred while submitting your OTP. Please try again, or contact us on 0508 287 672 or hello@aurorafinancial.co.nz ",
              ""
            );
      })
      .catch(error => {
        Swal.fire(
          "",
          "Unfortunately an error has occurred while submitting your OTP. Please try again, or contact us on 0508 287 672 or hello@aurorafinancial.co.nz ",
          ""
        );
      });
  };

  let verdata = {
    to: "+" + formData.countryCode + formData.number,
    verification_code: formData.verification
  };
  const onSubmitverifyotp1 = async () => {
    await axios
      .post(twilio + "/check-verify", verdata)
      .then(res => {
        res.status === 200 && res.data.status !== "pending"
          ? next()
          : $(".verificationerror").html(
              "Incorrect verification code entered. Please try again."
            );
      })
      .catch(error => {
        $(".verificationerror").html(
          "Incorrect verification code entered. Please try again."
        );
      });
  };

  if (!formData.countryCode) {
    formData.countryCode = +64;
  } else {
  }

  function setPass(e) {
    setOtp("Verify mobile");
    if (e.length !== 9) {
      $(".eerror").html(
        "Enter your full mobile number starting with 021, 022, 027 etc"
      );
    } else {
      removeError();
    }
  }

  $(document).ready(function() {
    $("button").click(function() {
      let key = $(".mobileZoom").attr("value");
      if (key.lenght > 9) {
        $(".eerror").hide();
      }
    });
  });

  useEffect(() => {
    $(document).ready(function() {
      $(".quantity").keypress(function(e) {
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 45 ||
          e.which === 38 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          return true;
        } else {
          return false;
        }
      });
    });
    $(document).ready(function() {
      $(".numbers").keypress(function(e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $(".digitsOnly").keypress(function(e) {
      var regex = new RegExp(/^[a-zA-Z\s/0-9]+$/);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });
  return (
    <div className="form">
      <div className="headerlogo">
        <img src={auroralogo} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Provide your contact details</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col md={12}>
                <label>Mobile number</label>
              </Col>

              <Col md={3} xs={12} className="numberSet mt-1 mb-2 col-xs-12">
                <PhoneInput
                  className="counrtyselext5 digitsOnlynone "
                  readonly
                  disabled={true}
                  country={"nz"}
                  preferredCountries={["nz"]}
                  name="countryCode"
                  // value={value}
                  // onChange={setValue}
                  // value={FormData.countryCode}
                  onChange={phone => {
                    formData.countryCode = phone;
                  }}
                />
              </Col>

              <Col md={6} xs={12}>
                <InputMask
                  mask="999999999999"
                  maskChar={null}
                  type="tel"
                  name="number"
                  value={formData.number ? formData.number : ""}
                  // placeholder="Enter mobile"
                  className="mobileZoom"
                  placeholder="Enter number"
                  required
                  onChange={e => {
                    setForm(e);
                    setPass(e);
                  }}
                  onClick={e => {
                    setPass(e);
                  }}
                />
                <span className="error mobileerror eerror" />
                {otp === "Code Sent" ? (
                  <span className="msg-show">
                    {" "}
                    Please check your mobile for the verification code.{" "}
                  </span>
                ) : null}
              </Col>
              <Col md={3} xs={6} className="mt-1">
                <button
                  type="button"
                  className={
                    otp === "Verify mobile"
                      ? "border_trans_btn"
                      : "border_trans_btn disable"
                  }
                  onClick={verifyMobile}>
                  {otp}
                  <img
                    src={backarrow}
                    className="arrow_size oneeightyrotate"
                    alt="arrow"
                  />
                </button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label name="verification">
                  Verification code
                  <img
                    src={infobtn}
                    className="btn_icon"
                    onClick={VerifyData}
                    alt="info"
                  />
                </label>
              </Col>
              <Col md={6}>
                <input
                  mask="999999"
                  id="inpt"
                  maxLength={6}
                  name="verification"
                  placeholder="Enter unique verification code"
                  className="numbers"
                  value={formData.verification ? formData.verification : ""}
                  onChange={e => {
                    setForm(e);
                    removeError();
                  }}
                />
                <span className="error verificationerror" id="errorOtp" />
              </Col>
            </Row>
            <Row className="closehome">
              <Col lg={12}>
                <label>Home address</label>
                <Googleaddress
                  name="fullAddress"
                  maxlength="200"
                  required
                  placeholder="Enter your home address"
                  id="resAddrs"
                  value={formData.fullAddress ? formData.fullAddress : ""}
                  onChange={e => {
                    setForm(e);
                    removeError();
                  }}
                />
              
                <span className="error homeaddresserror" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <Button
                    type="button"
                    className="preview trans_btn"
                    onClick={previousHandler}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </Button>
                  <Button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    NEXT
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Third;
